import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { RESOURCE_PATH_TOKEN, ResourceTypes, MapperInjectorService as RestApiClientMapperInjectorService } from '@big-direkt/rest-api-client';
import { MapperInjectorService as JsonApiClientMapperInjectorService, ResourceTypes as JsonApiClientResourceTypes } from '@big-direkt/json-api-client';
import { MybigInboxMapper } from './mybig-inbox.mapper';
import { DocumentsMapper } from './response-mapper/documents.mapper';
import { InquiriesMapper } from './response-mapper/inquiries.mapper';
import { MessagesUnreadCountMapper } from './response-mapper/messages-unread-count.mapper';
import { MessagesMapper } from './response-mapper/messages.mapper';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { DocumentsUnreadCountMapper } from './response-mapper/documents-unread-count.mapper';

export const provideMyBigInboxParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: JsonApiClientMapperInjectorService.tokens[JsonApiClientResourceTypes.ParagraphBigMyBigInbox], useClass: MybigInboxMapper },
        { provide: RestApiClientMapperInjectorService.tokens[InquiriesMapper.type], useClass: InquiriesMapper },
        { provide: RestApiClientMapperInjectorService.tokens[MessagesMapper.type], useClass: MessagesMapper },
        { provide: RestApiClientMapperInjectorService.tokens[MessagesUnreadCountMapper.type], useClass: MessagesUnreadCountMapper },
        { provide: RestApiClientMapperInjectorService.tokens[DocumentsMapper.type], useClass: DocumentsMapper },
        { provide: RestApiClientMapperInjectorService.tokens[DocumentsUnreadCountMapper.type], useClass: DocumentsUnreadCountMapper },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.Inquiries]: '/inquiries' }, multi: true },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.Messages]: '/messages' }, multi: true },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.MessagesUnreadCount]: '/messages/unread-count' }, multi: true },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.Documents]: '/documents' }, multi: true },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.DocumentsUnreadCount]: '/documents/unread-count' }, multi: true },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./mybig-inbox.component'),
                type: ParagraphTypes.MyBigInbox,
            },
            multi: true,
        },
    ]);
