import { Injectable, signal } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type DocumentModel, type DocumentsModel } from '../models';
import { type DocumentsResource } from '../models/documents.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class DocumentsMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.Documents;

    public map(resource: DocumentsResource): DocumentsModel {
        const documents: DocumentModel[] = [];

        for (const entry of resource.attributes.data.documents) {
            documents.push({
                creationDate: new Date(entry.creation_date),
                documentId: entry.document_id,
                documentName: entry.document_name,
                documentType: entry.document_type,
                documentUrl: entry.document_url,
                downloadLink: entry.document_url ?? `/documents/${entry.document_id}/download`,
                fileName: `big-direkt_${entry.creation_date}_${entry.document_name}`,
                // eslint-disable-next-line no-null/no-null
                readDate: entry.read_date !== null ? new Date(entry.read_date) : null,
                isLoading: signal<boolean>(false),
            });
        }

        return {
            documents,
            totalCount: resource.attributes.data.total_count,
        };
    }
}
