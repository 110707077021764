import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type MessageModel } from '../models/message.model';
import { type MessagesModel } from '../models/messages.model';
import { type MessagesResource } from '../models/messages.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class MessagesMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.Messages;

    public map(resource: MessagesResource): MessagesModel {
        const messages: MessageModel[] = [];

        for (const message of resource.attributes.messages) {
            messages.push({
                messageId: message.message_id,
                subject: message.subject,
                from: message.from,
                date: message.date,
                state: message.state,
                text: message.text,
                inquiryId: message.inquiry_id ?? undefined,
            });
        }

        return {
            messages,
            totalCount: resource.attributes.totalCount,
        };
    }
}
