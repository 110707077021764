import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type InquiriesModel } from '../models';
import { type InquiriesResource } from '../models/inquiries.resource';
import { type InquiryModel } from '../models/inquiry.model';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class InquiriesMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.Inquiries;

    public map(resource: InquiriesResource): InquiriesModel {
        const inquiries: InquiryModel[] = [];

        for (const inquiry of resource.attributes.inquiries) {
            inquiries.push({
                dateProcessed: inquiry.date_processed ?? undefined,
                dateCreated: inquiry.date_created,
                prospectId: inquiry.prospect_id,
                messagesReadCount: inquiry.messages_read_count,
                messagesUnreadCount: inquiry.messages_unread_count,
                status: inquiry.status,
                inquiryId: inquiry.inquiry_id,
                inquiryName: inquiry.inquiry_name,
            });
        }

        return {
            inquiries,
            totalCount: resource.attributes.totalCount,
        };
    }
}
