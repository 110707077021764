import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type DocumentsUnreadCountModel } from '../models/documents-unread-count.model';
import { type DocumentsUnreadCountResource } from '../models/documents-unread-count.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class DocumentsUnreadCountMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.DocumentsUnreadCount;

    public map(resource: DocumentsUnreadCountResource): DocumentsUnreadCountModel {
        return {
            unreadCount: resource.attributes.data.unread_count,
        };
    }
}
