import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type MessagesUnreadCountModel } from '../models/messages-unread-count.model';
import { type MessagesUnreadCountResource } from '../models/messages-unread-count.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class MessagesUnreadCountMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.MessagesUnreadCount;

    public map(resource: MessagesUnreadCountResource): MessagesUnreadCountModel {
        return {
            unreadCount: resource.attributes.unread_count,
        };
    }
}
