import { Injectable } from '@angular/core';
import {
    JsonApiResourceMapper,
    type ResourceIdentifier,
    ResourceMapper,
    ResourceTypes,
} from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type MyBigInboxModel } from './mybig-inbox.model';
import { type MybigInboxResource } from './mybig-inbox.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class MybigInboxMapper extends ResourceMapper<MyBigInboxModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigMyBigInbox;

    public map(resource: MybigInboxResource): MyBigInboxModel {
        const response: MyBigInboxModel = {
            type: ParagraphTypes.MyBigInbox,
            inboxType: resource.attributes.inbox_type,
            consentWebform: '',
            missingEmailWebform: '',
        };

        /**
         * consentWebform and missingEmailWebform are only used in messages and not inquiries context
         */
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/dot-notation
        if ((resource.relationships?.consent_webform?.data as ResourceIdentifier)?.meta?.['webform_id']) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            response.consentWebform = (resource.relationships?.consent_webform?.data as ResourceIdentifier).meta?.['webform_id'];
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/dot-notation
        if ((resource.relationships?.missing_email_webform?.data as ResourceIdentifier)?.meta?.['webform_id']) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            response.missingEmailWebform = (resource.relationships?.missing_email_webform?.data as ResourceIdentifier).meta?.['webform_id'];
        }

        return response;
    }
}
